import moment from 'moment';

function getRandomBrightHexColor() {
  const r = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for red
  const g = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for green
  const b = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for blue
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export const getSetupTopPodcastsChart = (data, chosenCountry) => {
  const filteredData = data.filter(({ country }) => country === chosenCountry);
  const labels = [
    ...new Set([
      ...filteredData
        .map(({ historyRank }) => historyRank)
        .flat()
        .sort((a, b) => moment(a.date).diff(b.date))
        .map(({ date }) => moment(date).format('MMM DD')),
    ]),
  ];

  const colors = ['#eda440', '#de5a35', '#4896c0'];

  return filteredData.reduce(
    (accum, item) => {
      const color = colors.shift() || getRandomBrightHexColor();
      accum.genres.push({ name: item.genre, color });
      accum.datasets.push({
        label: item.genre,
        data: item.historyRank.map(({ rank, date }) => ({
          x: moment(date).format('MMM DD'),
          y: rank,
        })),
        backgroundColor: color,
        borderColor: color,
        hidden: !!item.hidden,
      });
      return accum;
    },
    {
      labels,
      datasets: [],
      genres: [],
    }
  );
};