import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  chart: {
    '& canvas': {
      boxShadow:
        'inset 0px 0px 0px 0px #ffffff00, inset 0 0px 0px 0px #ffffff00, inset 30px 0 15px -4px rgba(23, 23, 23, 0.8), inset -30px 0 15px -4px rgba(23, 23, 23, 0.8)',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 150px 15px',
    [theme.breakpoints.down('md')]: {
      padding: '0 15px 15px',
    },
  },
}));
