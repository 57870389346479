import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';

export const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: 400,
    margin: '0 20px 0 0',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: '0 0 20px',
    },
  },
  searchTitle: {
    fontSize: 18,
    fontWeight: '400',
    paddingBottom: 15,
    color: 'white',
    fontFamily: 'Montserrat',
  },
  searchForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  searchSelect: {
    padding: '8px',
    fontSize: '16px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  searchButton: {
    cursor: 'pointer',
    width: 120,
    height: 40,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 5,

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  searchInput: {
    padding: '8px 10px 8px 8px',
    fontSize: 16,
    width: '100%',
    border: '1px solid #ccc',
    height: 24,
    borderRadius: 10,
    '&::placeholder': {
      fontStyle: 'italic',
      fontWeight: '300',
      letterSpacing: '0.5px',
      paddingLeft: 10,
      fontFamily: 'Montserrat',
    },
  },
  searchControls: {
    display: 'flex',
    gap: 10,
    alignItems: 'flex-start',
  },
  selectPodcast: {
    padding: '8px 10px',
    fontSize: 16,
    width: '100%',
    border: '1px solid #ccc',
    height: 40,
    borderRadius: 10,
    marginTop: 5,
  },
  searchAutocomplete: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: 10,
      height: 43,
      maxWidth: 315,
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100%',
      },
      '&:hover': {},
    },
    '& .MuiAutocomplete-inputRoot': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& input': {
        padding: '8px 10px 8px 8px',
        fontSize: 16,
        height: 24,
        borderRadius: 10,
        '&::placeholder': {
          fontStyle: 'italic',
          fontWeight: 300,
          letterSpacing: '0.5px',
          paddingLeft: 10,
          fontFamily: 'Montserrat',
          color: 'rgb(0, 0, 0)',
        },
      },
      '& fieldset': {
        border: '1px solid #ccc',
        borderRadius: 10,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f66000 !important',
        borderWidth: 2,
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      right: 8,
      '& svg': {
        color: '#f66000',
      },
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 32,
      height: 32,
    },
  },
}));

export const StyledPopper = styled(Popper)({
  '& .MuiPaper-root': {
    borderRadius: 10,
    marginTop: 5,
    backgroundColor: 'white',
  },
});
