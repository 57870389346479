import React from 'react';
import { Box } from '@mui/material';
import bg from '../assets/images/bg.svg';
import bgBtn from '../assets/images/btn.png';
import mowpodCharts from '../assets/images/mowpod-charts.png';
import { Search } from '../components/Search/Search';
import { EmailNotify } from '../components/EmailNotify/EmailNotify';
import { PodcastInfo } from '../components/PodcastInfo/PodcastInfo';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { PodcastCharts } from '../components/PodcastCharts/PodcastCharts';
import Chart from '../components/Chart/Chart';
export const Charts = () => {
  const { currentPodcast } = useSelector((state) => state.podcasts);
  const classes = useStyles({ bg, bgBtn });

  return (
    <Box className={classes.container}>
      <Box className={classes.logoWrapper}>
        <img src={mowpodCharts} alt="Mowpod Charts" className={classes.logo} />
      </Box>
      <Box className={classes.header}>
        <Box className={classes.headerTop}>
          <Search />
          <EmailNotify />
        </Box>
        <PodcastInfo />
      </Box>
      <Chart />
      <Box className={classes.header}>
        {currentPodcast && <PodcastCharts />}
        <Box className={classes.divider} />
      </Box>
    </Box>
  );
};
