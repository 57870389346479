import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPodcast, resetPodcasts, fetchPodcasts } from '../../redux/slices/podcastsSlice';
import { Box, Typography, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useStyles, StyledPopper } from './styles';
import debounce from 'lodash/debounce';

export const Search = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { podcasts, status } = useSelector((state) => state.podcasts);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const loading = status === 'loading';

  const debouncedFetch = useCallback(
    (value) => {
      if (value.trim()) {
        dispatch(fetchPodcasts(value));
      }
    },
    [dispatch]
  );

  const debouncedSearch = useMemo(() => debounce(debouncedFetch, 800), [debouncedFetch]);

  const handleInputChange = (event, value) => {
    debouncedSearch(value);
    setSearchValue(value);
  };

  const handlePodcastSelect = (event, podcast) => {
    if (podcast) {
      dispatch(setCurrentPodcast(podcast));
    }
  };

  return (
    <Box className={classes.searchWrapper}>
      <Typography variant="h7" component="h2" className={classes.searchTitle}>
        Find your podcast
      </Typography>
      <Autocomplete
        slots={{
          popper: StyledPopper,
        }}
        className={classes.searchAutocomplete}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event, newValue) => {
          handlePodcastSelect(event, newValue);
          if (!newValue) {
            dispatch(setCurrentPodcast(null));
            dispatch(resetPodcasts());
          }
        }}
        inputValue={searchValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option.collectionId === value.collectionId}
        getOptionLabel={(option) => option.collectionName || ''}
        getOptionKey={(option) => option.collectionId}
        options={podcasts}
        loading={loading}
        clearOnBlur={false}
        noOptionsText="No podcasts"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Podcast name"
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.collectionId} style={{ gap: '10px' }}>
            {option.artworkUrl30 && <img src={option.artworkUrl30} alt="" />}
            {option.collectionName}
          </li>
        )}
      />
    </Box>
  );
};
