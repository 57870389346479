import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'auto',
    gap: '5px 30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
    },
  },
  chartItem: {
    backgroundColor: 'transparent',
    border: '0.5px solid grey',
    borderRadius: '10px',
    color: 'white',
    textAlign: 'left',
    margin: '5px 0',
    padding: '5px 10px',
  },
  showMoreButton: {
    width: 140,
    margin: '0 auto !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
