import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import cx from 'classnames';
import { useStyles } from './styles';
import { Fragment } from 'react';
export const PodcastInfo = () => {
  const classes = useStyles();
  const { currentPodcast } = useSelector((state) => state.podcasts);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  if (!currentPodcast) return null;

  return (
    <Box className={classes.podcastInfo}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <img src={currentPodcast.artworkUrl60} alt={currentPodcast.name} className={classes.podcastImage} />
        <Box className={classes.podcastDetailsWrapper}>
          <Typography className={cx(classes.podcastInfoText, classes.podcastTitle)}>
            {currentPodcast.collectionName}
          </Typography>
          <Box className={classes.podcastDetails}>
            <Typography className={classes.podcastInfoText}>
              Released on {formatDate(currentPodcast.releaseDate)}
            </Typography>
            <Typography className={classes.podcastEpisodes}>{currentPodcast.trackCount} episodes</Typography>
            <Typography className={classes.podcastCategories}>
              categories:{' '}
              {currentPodcast.genres && currentPodcast.genres.length > 0 && (
                <span>{currentPodcast.genres.map((genre, index) => (
                  <Fragment key={index}>
                    <span className={classes.categoryText}>{genre}</span>
                    {index < currentPodcast.genres.length - 1 && <span className={classes.divider}> | </span>}
                  </Fragment>
                ))}</span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
