import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import LensIcon from '@mui/icons-material/Lens';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getSetupTopPodcastsChart } from '../../utils/chart';

import { useStyles } from './styles';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  interaction: {
    mode: 'x',
    intersect: false,
  },
  aspectRatio: 4,
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      top: 20,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      min: 0,
      max: 200,
      reverse: true, // Reverse the y-axis
      grid: {
        color: '#212121', // Set grid color for y-axis
      },
      offset: true,
      grace: 50,
      position: { x: 1 }, // Default position, adjust layout instead
      ticks: {
        color: '#b4b4b4',
        stepSize: 25, // Set step size for y-axis
      },
    },
    x: {
      grid: {
        color: '#212121', // Set grid color for x-axis
      },
      ticks: {
        color: '#b4b4b4',
        align: 'start',
        callback: function (value, index, values) {
          if (index === 0 || index === values.length - 1) return '';
          return this.getLabelForValue(value);
        },
      },
    },
  },
};

const initialState = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    },
  ],
};

const Chart = () => {
  const classes = useStyles();
  const [chartData, setChartData] = useState(initialState);
  const [countries, setCountries] = useState([]);
  const [chosenCountry, setChosenCountry] = useState('United States');
  const [genres, setGenres] = useState([]);
  const [hiddenGenres, setHiddenGenres] = useState([]);
  const {
    topPodcasts: { data, loading },
  } = useSelector((state) => state.podcasts);
  const chart = useRef(null);

  useEffect(() => {
    if (!data?.length) return;
    const countries = [...new Set(data.map((item) => item.country))];

    const sortedCountries = countries.includes('United States')
      ? ['United States', ...countries.filter((country) => country !== 'United States')]
      : countries;
    setChosenCountry(sortedCountries[0]);
    setCountries(sortedCountries);
    const setup = getSetupTopPodcastsChart(data, sortedCountries[0]);
    setGenres(setup.genres);
    console.log('setup', setup);
    setChartData(setup);
  }, [data]);

  const handleChangeCountry = (event) => {
    const setup = getSetupTopPodcastsChart(data, event.target.value);
    setChosenCountry(event.target.value);
    setChartData(setup);
    setGenres(setup.genres);
    setHiddenGenres([]);
  };

  const handleHideGenre = (name) => {
    let newGenres = [];
    if (hiddenGenres.includes(name)) {
      newGenres = hiddenGenres.filter((item) => item !== name);
      setHiddenGenres(newGenres);
    } else {
      newGenres = [...hiddenGenres, name];
      setHiddenGenres(newGenres);
    }
    const setup = getSetupTopPodcastsChart(
      data.map((item) => (!newGenres.includes(item.genre) ? item : { ...item, hidden: true })),
      chosenCountry
    );
    setChartData(setup);
  };

  const renderChart = () =>
    loading ? (
      <Grid2 container item md={12} justifyContent="center">
        <CircularProgress />
      </Grid2>
    ) : (
      <>
        <Grid2
          className={classes.header}
          container
          item
          md={12}
          spacing={1}
          justifyContent="space-between"
        >
          <Grid2 item md={3}>
            <FormControl variant="outlined" sx={{ m: 1, borderRadius: '15px' }}>
              <Select
                defaultValue={chosenCountry}
                value={chosenCountry}
                onChange={handleChangeCountry}
                sx={{ borderRadius: '15px' }}
                inputProps={{
                  sx: {
                    border: '1px solid white',
                    borderRadius: '15px',
                    '&:focus': {
                      borderRadius: '15px',
                    },
                  },
                }}
              >
                {countries.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 container item md={9}>
            {genres.map(({ name, color }) => (
              <Grid2
                container
                item
                md={3}
                key={name}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleHideGenre(name)}
              >
                <Box>
                  <LensIcon sx={{ color }} />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: 'white',
                      textDecoration: hiddenGenres.includes(name) ? 'line-through' : 'none',
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
        <Grid2 className={classes.chart} container item md={12}>
          <Line ref={chart} data={chartData} options={options} />
        </Grid2>
      </>
    );

  return data?.length && !loading ? renderChart() : <></>;
};

export default Chart;
