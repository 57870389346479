import axios from 'axios';

export const searchPodcasts = async (name) => {
  const response = await axios.get(`/api/top-podcasts/search?name=${name}`);
  return response.data;
};

export const getTopPodcasts = async (params) => {
  const response = await axios.get(`/api/top-podcasts/`, { params });
  return response.data;
};
