import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  podcastInfo: {
    marginTop: 20,
    padding: 16,
    borderRadius: 8,
    width: '100%',
    '& $podcastInfoText': {
      color: 'white',
      fontSize: 16,
      fontWeight: 300,
    },
    '& $podcastImage': {
      borderRadius: 4,
    },
    '& $podcastTitle': {
      color: 'white',
      fontSize: 22,
      fontWeight: 800,
    },
    '& $podcastEpisodes': {
      fontSize: 16,
      fontWeight: 500,
      color: 'white',
    },
    '& $podcastCategories': {
      fontSize: 16,
      fontWeight: 300,
      color: 'white',
    },
  },
  podcastDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 25,
  },
  podcastDetailsWrapper: {
    display: 'block',
    textAlign: 'left',
  },
  podcastInfoText: {},
  podcastImage: {},
  podcastTitle: {},
  podcastEpisodes: {},
  podcastCategories: {},
  categoryText: {
    color: '#46c0ec',
  },
  divider: {
    color: 'white',
  },
}));
