import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  notifyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: 400,
    margin: '0 0 0 20px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: '0 0 20px',
    },
  },
  notifyTitle: {
    fontSize: 18,
    fontWeight: '400',
    paddingBottom: 15,
    color: 'white',
    fontFamily: 'Montserrat',
  },  
  notifyForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  notifyButton: {
    cursor: 'pointer',
    width: 120,
    height: 40,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 5,

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  notifyInput: {
    padding: '8px 10px 8px 8px',
    fontSize: 16,
    width: '100%',
    border: '1px solid #ccc',
    height: 24,
    borderRadius: 10,
    '&::placeholder': {
      fontStyle: 'italic',
      fontWeight: '300',
      letterSpacing: '0.5px',
      paddingLeft: 10,
      fontFamily: 'Montserrat',
    }
  },
  notifyControls: {
    display: 'flex',
    gap: 10,
    alignItems: 'flex-start',
  }
}));


