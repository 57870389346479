import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    background: {
      default: '#F4F6F8',
      paper: '#000',
    },
    primary: {
      contrastText: '#ffffff',
      main: '#ff7000',
    },
    text: {
      primary: '#9c9c9c',
      secondary: '#6b778c',
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: '#fff'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: '#fff',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#ffffffa3',
        '&.Mui-focused': {
          color: '#f7a128',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
          color: '#000',
          borderRadius: 10,
          padding: 10,
        },
      },
      iconOutlined: {
        color: '#000 !important',
      },
      root: {
        '&$outlined': {
          backgroundColor: '#fff',
          color: '#000',
          borderRadius: 10,
          padding: 10,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          // borderColor: 'none',
          borderRadius: 10,
        },
        '&:hover $notchedOutline': {
          borderColor: 'none',
        },
        '&$focused $notchedOutline': {
          borderColor: 'none',
        },
      },
    },
    MuiTablePagination: {
      root: {
        color: 'grey',
      }
    },
    MuiTypography: {
      root: {
        color: '#fff',
      }
    },
    MuiButton: {
      label: {
        fontWeight: 'bold',
        color: '#fff',
      },
    },
    MuiButtonBase: {
      root: {
        color: '#000'
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#808080'
      }
    }
  },
});

