import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { sendEmail } from '../../api/email';
import bgBtn from '../../assets/images/btn-get-em.png';
import { 
  Button, 
  Box, 
  CircularProgress,
  Typography
} from '@mui/material';
import { useStyles } from './styles';

export const EmailNotify = () => {
  const classes = useStyles({ bgBtn });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (values.email.trim()) {
      setIsLoading(true);
      try {
        await sendEmail(values.email);
        resetForm();
      } catch (error) {
        console.error('Error sending email:', error);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    }
  };

  return (
    <Box className={classes.notifyWrapper}>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form>
            <Box className={classes.notifyForm}>
              <Typography variant="h7" component="h2" className={classes.notifyTitle}>
              Get daily notifications on this podcast
              </Typography>
              <Box className={classes.notifyControls}>
                <Field
                  name="email"
                  type="email"
                  placeholder="Your email"
                  className={classes.notifyInput}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                <Button 
                  type="submit"
                  disabled={isLoading}
                  className={classes.notifyButton}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    ''
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
