import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTopPodcasts, searchPodcasts } from '../../api/podcasts';

export const fetchPodcasts = createAsyncThunk(
  'podcasts/fetchPodcasts',
  async (searchValue) => {
    const response = await searchPodcasts(searchValue);
    return response;
  }
);

export const fetchTopPodcasts = createAsyncThunk(
  'podcasts/fetchTopPodcasts',
  async (params) => {
    const response = await getTopPodcasts(params);
    return response;
  }
);

const podcastsSlice = createSlice({
  name: 'podcasts',
  initialState: {
    podcasts: [],
    currentPodcast: null,
    topPodcasts: {
      data: [],
      loading: false,
      error: null,
    },
    status: '',
    error: null
  },
  reducers: {
    setCurrentPodcast: (state, action) => {
      state.currentPodcast = action.payload;
    },
    resetPodcasts: (state) => {
      state.podcasts = [];
      state.topPodcasts = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodcasts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPodcasts.fulfilled, (state, action) => {
        state.status = 'success';
        state.podcasts = action.payload;
      })
      .addCase(fetchPodcasts.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(fetchTopPodcasts.pending, (state) => {
        state.topPodcasts.loading = true;
      })
      .addCase(fetchTopPodcasts.fulfilled, (state, action) => {
        state.topPodcasts.loading = false;
        state.topPodcasts.data = action.payload;
      })
      .addCase(fetchTopPodcasts.rejected, (state, action) => {
        state.topPodcasts.loading = false;
        state.topPodcasts.error = action.error.message;
      });
  }
});

export const { setCurrentPodcast, resetPodcasts } = podcastsSlice.actions;
export default podcastsSlice.reducer; 